<script setup lang="ts">
import type { HeaderListViewProps } from './HeaderListView.props'

defineProps<HeaderListViewProps>()

const localePath = useLocalePath()
</script>

<template>
  <OrganismsPopover :id="id" :is-full-height-mobile="false" hide-close-button>
    <template #body>
      <div class="flex flex-col">
        <div
          class="bg-neutral-white sticky top-0 z-10 flex justify-center py-5"
        >
          <DefaultIconsGlobalLogo class="h-5" />
        </div>
        <div class="grid-standard pb-40">
          <div
            v-for="card in cards"
            :key="`card-${card.logo?.public_id}`"
            class="relative col-span-6 grid"
          >
            <NuxtLink
              :to="
                card.cta?.internalLink?.urlSlug
                  ? localePath(card.cta?.internalLink.urlSlug)
                  : card.cta?.externalLink
              "
              class="opacity-layer"
            >
              <NuxtPicture
                v-show="card.media?.secure_url"
                :src="card.media?.secure_url ?? ''"
                :alt="card.media?.public_id"
                provider="cloudinary"
                class="aspect-[4/5] w-full"
                sizes="sm:50vw"
                :modifiers="{ aspectRatio: '4:5' }"
                :img-attrs="{
                  class: 'w-full h-auto',
                }"
                quality="80"
                loading="lazy"
                fit="fill"
              />
            </NuxtLink>
            <div
              class="p-sm text-neutral-white absolute flex w-full justify-center self-end"
            >
              <NuxtLink
                :to="
                  card.cta?.internalLink?.urlSlug
                    ? localePath(card.cta?.internalLink.urlSlug)
                    : card.cta?.externalLink
                "
              >
                <NuxtPicture
                  v-show="card.logo"
                  :src="card.logo?.secure_url ?? ''"
                  :alt="card.logo?.public_id"
                  provider="cloudinary"
                  sizes="sm:50vw"
                  loading="lazy"
                />
              </NuxtLink>
            </div>
          </div>
        </div>
      </div>
    </template>
  </OrganismsPopover>
</template>

<style scoped lang="scss">
.opacity-layer:after {
  content: '';
  background: linear-gradient(
    0deg,
    rgba(0, 0, 0, 1) -10%,
    rgba(255, 255, 255, 0) 30%,
    rgba(255, 255, 255, 1) 250%
  );
  position: absolute;
  top: 0px;
  display: block;
  width: 100%;
  height: 100%;
  opacity: 0.7;
}
</style>
